<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <mutacao-bdgd-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getAll"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MutacaoBdgdService from '@/services/MutacaoBdgdService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import MutacaoBdgdDataTable from '@/components/mutacao-bdgd/MutacaoBdgdDataTable';

export default {
  mixins: [refreshDataMixins],
  components: {
    MutacaoBdgdDataTable
  },
  data: () => ({
    items: [],
    loading: false
  }),
  created() {
    this.refreshData(this.getAll);
  },
  methods: {
    getAll() {
      this.loading = true;
      MutacaoBdgdService.getAll()
        .then(({ data }) => (this.items = data.data))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
