<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-compare-horizontal"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Mutação da BDGD</div>
      </template>
      <v-btn
        v-if="accessReleased('MUTACAO_BDGD_ADICIONAR')"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push('add')"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Novo Relatório
      </v-btn>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />
      <general-progress-bars
        class="mt-11"
        :items="downloadProgressData"
        prefixText="Baixando resultado ..."
      />
      <v-divider class="mt-10" />
      <v-data-table
        :headers="[
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'Título',
            value: 'nome'
          },
          {
            text: 'BDGD Antiga',
            value: 'bdgd_antiga'
          },
          {
            text: 'BDGD Atual',
            value: 'bdgd_atual'
          },
          {
            text: 'Base AIS',
            value: 'base_ais'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            sortable: false,
            text: 'Ações',
            value: 'actions',
            class: 'pl-4'
          }
        ]"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhum relatório encontrado"
      >
        <template v-slot:[`item.bdgd_antiga`]="{ item }">
          {{ item.bdgd_antiga_data_registro | formatToMonth }} v{{
            item.bdgd_antiga_versao
          }}
        </template>
        <template v-slot:[`item.bdgd_atual`]="{ item }">
          {{ item.bdgd_atual_data_registro | formatToMonth }} v{{
            item.bdgd_atual_versao
          }}
        </template>
        <template v-slot:[`item.base_ais`]="{ item }">
          Data: {{ item.data_ais | formatToMonth }}<br />
          Período:
          {{ item.data_inicio_ais | parseToDateBR }} a
          {{ item.data_fim_ais | parseToDateBR }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <general-status :status="item.status" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :class="!podeBaixarZip(item.status) ? 'cursor-block' : ''"
            class="px-1"
            min-width="0"
            fab
            icon
            x-small
            @click="baixarZip(item)"
            :loading="
              downloadProgressData[item.id] !== undefined ? true : false
            "
            :disabled="!podeBaixarZip(item.status)"
          >
            <v-icon small> mdi-download </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="podeExecutarRelatorio(item.status)"
                @click="executarRelatorio(item)"
              >
                <v-list-item-title>
                  <v-icon small> mdi-play </v-icon>
                  Executar
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="canDeleteItem(item)"
                @click="openDialogDelete(item)"
              >
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Deletar </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openDialogCreationLog(item)">
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable')"
              >
                mdi-refresh
              </v-icon>
            </template>
            <span> Clique aqui para recarregar os relatórios </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title">
        Deletar Relatório #{{ dialogDeleteData.id }}
      </template>
      <template slot="body">
        Tem certeza que deseja deletar o relatório
        <strong> #{{ dialogDeleteData.id }} </strong>?
      </template>
    </dialog-delete>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import MutacaoBdgdService from '@/services/MutacaoBdgdService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins.js';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';
import DialogCreationLog from '@/components/general/DialogCreationLog';
import DialogDelete from '@/components/general/DialogDelete';
import GeneralStatus from '@/components/general/GeneralStatus';
import GeneralProgressBars from '@/components/general/GeneralProgressBars';

export default {
  mixins: [powerupsActionsMixins, profilePermissionsMixin],
  components: {
    DialogCreationLog,
    DialogDelete,
    GeneralStatus,
    GeneralProgressBars
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    search: '',
    dialogDelete: false,
    dialogDeleteData: { id: null },
    downloadProgressData: {}
  }),
  methods: {
    canDeleteItem(item) {
      return item.status === 'AGUARDANDO';
    },
    podeExecutarRelatorio(status) {
      return status === 'SUCESSO' || status === 'FALHA';
    },
    openDialogCreationLog(item) {
      this.dialogCreationLog = true;
      this.dialogCreationLogData = {
        username: item.usuario,
        created: item.created
      };
    },
    openDialogDelete(item) {
      this.dialogDelete = true;
      this.dialogDeleteData = {
        id: item.id
      };
    },
    deletarItem(id) {
      MutacaoBdgdService.deletar(id)
        .then(() => {
          this.$toast.success('Relatório removido com sucesso.', '', {
            position: 'topRight'
          });
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar o relatório.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
      this.dialogDelete = false;
    },
    executarRelatorio(relatorio) {
      MutacaoBdgdService.executarRelatorio(relatorio.id)
        .then(() => {
          this.$toast.info(
            'O processamento ocorrerá em instantes. Por favor, aguarde.',
            '',
            { position: 'topRight', timeout: 2000 }
          );
          this.$emit('rechargeTable');
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao agendar a execução do relatório ${relatorio.titulo}.`,
            '',
            {
              position: 'topRight'
            }
          );
        });
    },
    podeBaixarZip(status) {
      return status === 'SUCESSO' || status === 'FALHA';
    },
    baixarZip(mutacao) {
      const { id } = mutacao;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const nomeArquivo = `resultado-mutacao-bdgd-${id}-${timestamp}.zip`;

      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      MutacaoBdgdService.baixarZip(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo zip com o resultado da Mutação da BDGD.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    }
  }
};
</script>
