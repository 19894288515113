var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-compare-horizontal","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Mutação da BDGD")])]},proxy:true}])},[(_vm.accessReleased('MUTACAO_BDGD_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Novo Relatório ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('general-progress-bars',{staticClass:"mt-11",attrs:{"items":_vm.downloadProgressData,"prefixText":"Baixando resultado ..."}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{attrs:{"headers":[
        {
          text: '#',
          value: 'id'
        },
        {
          text: 'Título',
          value: 'nome'
        },
        {
          text: 'BDGD Antiga',
          value: 'bdgd_antiga'
        },
        {
          text: 'BDGD Atual',
          value: 'bdgd_atual'
        },
        {
          text: 'Base AIS',
          value: 'base_ais'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          class: 'pl-4'
        }
      ],"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhum relatório encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.bdgd_antiga",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.bdgd_antiga_data_registro))+" v"+_vm._s(item.bdgd_antiga_versao)+" ")]}},{key:"item.bdgd_atual",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.bdgd_atual_data_registro))+" v"+_vm._s(item.bdgd_atual_versao)+" ")]}},{key:"item.base_ais",fn:function(ref){
      var item = ref.item;
return [_vm._v(" Data: "+_vm._s(_vm._f("formatToMonth")(item.data_ais))),_c('br'),_vm._v(" Período: "+_vm._s(_vm._f("parseToDateBR")(item.data_inicio_ais))+" a "+_vm._s(_vm._f("parseToDateBR")(item.data_fim_ais))+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",class:!_vm.podeBaixarZip(item.status) ? 'cursor-block' : '',attrs:{"min-width":"0","fab":"","icon":"","x-small":"","loading":_vm.downloadProgressData[item.id] !== undefined ? true : false,"disabled":!_vm.podeBaixarZip(item.status)},on:{"click":function($event){return _vm.baixarZip(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.podeExecutarRelatorio(item.status))?_c('v-list-item',{on:{"click":function($event){return _vm.executarRelatorio(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")]),_vm._v(" Executar ")],1)],1):_vm._e(),(_vm.canDeleteItem(item))?_c('v-list-item',{on:{"click":function($event){return _vm.openDialogDelete(item)}}},[_c('v-list-item-icon',{staticClass:"mx-0 my-0 py-4"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1),_c('v-list-item-title',[_vm._v(" Deletar ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogCreationLog(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-table-search ")]),_vm._v(" Log ")],1)],1)],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('rechargeTable')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v(" Clique aqui para recarregar os relatórios ")])])]},proxy:true}],null,true)})],1),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}}),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialogDelete=$event},"submitTriggered":function($event){return _vm.deletarItem(_vm.dialogDeleteData.id)}}},[_c('template',{slot:"title"},[_vm._v(" Deletar Relatório #"+_vm._s(_vm.dialogDeleteData.id)+" ")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja deletar o relatório "),_c('strong',[_vm._v(" #"+_vm._s(_vm.dialogDeleteData.id)+" ")]),_vm._v("? ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }